import React, {useState, useEffect} from 'react';
import UploadCard from "./components/UploadCard/UploadCard";
import MainApp from "./MainApp";
import {v4 as uuidV4} from 'uuid';
import {useAppData, useAppDispatch} from "./contexts/AppContext";
import {Box, LinearProgress, Backdrop, Typography} from '@mui/material';

const addIdToTranscriptionData = (data) => {
  return data.map(word => ({...word, id: uuidV4()}));
};

export const baseURL = '/api';

function App() {
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [toTextProgress, setToTextProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useAppDispatch();
  const {file} = useAppData();

  useEffect(() => {
    if (uploadProgress === 100 && toTextProgress === 100) {
      setIsProcessing(false);
    }
  }, [uploadProgress, toTextProgress]);

  const handleFileSelect = async (selectedFile) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const uploadId = uuidV4();
    const socket = new WebSocket(`ws://${window.location.host}/api/?uploadId=${uploadId}`);

    try {
      socket.onopen = () => {
        setIsProcessing(true);

        const xhr = new XMLHttpRequest();

        xhr.open('POST', `${baseURL}/upload?uploadId=${uploadId}`, true);

        // 监听上传进度事件
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            setUploadProgress(percentComplete);
            console.log(`上传进度：${percentComplete.toFixed(2)}%`);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            setUploadProgress(100); // 上传完成

            const data = JSON.parse(xhr.responseText);

            let payloadFile = selectedFile;

            if (data.videoPath) {
              dispatch({
                type: 'SET_VIDEO_PATH',
                payload: data.videoPath
              });
              const byteCharacters = atob(data.audioBase64);
              const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
              const byteArray = new Uint8Array(byteNumbers);
              payloadFile = new Blob([byteArray], {type: 'audio/wav'});
            }

            dispatch({
              type: 'SET_FILE',
              payload: payloadFile
            });
            dispatch({
              type: 'SET_TRANSCRIPTION_DATA',
              payload: addIdToTranscriptionData(data.transcription.result)
            });

            setIsUploaded(true);
          } else {
            console.error('上传失败', xhr.statusText);
            setIsProcessing(false);
          }
        };

        xhr.onerror = () => {
          console.error('上传过程中发生错误');
          setIsProcessing(false);
        };

        xhr.send(formData);
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'to_text_progress') {
          setToTextProgress(message.progress);
          console.log('语音转文字进度', message.progress);
        }
      };
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsProcessing(false);
    }
  };

  return (
    <div className="App">
      {!isUploaded ? (
        <>
          <UploadCard onFileSelect={handleFileSelect}/>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.85)' // 调整背景颜色，使其更深
            }}
            open={isProcessing}
          >
            <Box sx={{width: '50%', mb: 2}}>
              <Typography variant="h6" sx={{mb: 1}}>
                上传进度: {uploadProgress.toFixed(2)}%
              </Typography>
              <LinearProgress variant="determinate" value={uploadProgress}/>
            </Box>
            <Box sx={{width: '50%', mt: 2}}>
              <Typography variant="h6" sx={{mb: 1}}>
                语音转文字进度: {toTextProgress.toFixed(2)}%
              </Typography>
              <LinearProgress variant="determinate" value={toTextProgress}/>
            </Box>
          </Backdrop>
        </>
      ) : (
        <MainApp file={file}/>
      )}
    </div>
  );
}

export default App;
